import { fileToBase64 } from './fileToBase64'

export type TFile = {
  file_name: string
  byte_data: string
}

/**
 *
 * @param fu  - FineUploader instance
 */
export async function extractFileFromFineUploader(fu: any): Promise<TFile | undefined> {
  const files = fu.methods.getUploads({ status: fu.qq.status.SUBMITTED })
  if (files.length === 0) return

  const f = files[0]
  const b64 = await fileToBase64(f.file)
  const payload = {
    file_name: f.name,
    byte_data: b64,
  }
  return payload
}
