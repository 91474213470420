export function b64_to_utf8(str: string) {
  if (!str) {
    return ''
  }
  return decodeURIComponent(window.atob(str))
}
export function utf8_to_b64(str: string) {
  return window.btoa(encodeURIComponent(str))
}

export function b64_to_string(str: string) {
  if (!str) {
    return ''
  }
  return Buffer.from(str, 'base64').toString('utf-8')
}

export function string_to_b64(str: string | undefined) {
  if (!str) {
    return ''
  }
  return Buffer.from(str, 'utf-8').toString('base64')
}
