import React from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'
import { Trans } from '@lingui/react'
import { Bar, BarItem, Input, Addon, Icon, ButtonLayout, ButtonPrimary } from '../../../ui-kit'

export interface SearchBarProps {
  onSearchClick?: (term?: string) => void
  onChange?: (term?: string) => void
  placeholder: string
  loading?: boolean
  defaultValue?: string
}

export const SearchBar: React.FC<SearchBarProps> = observer(function SearchBar({
  onSearchClick,
  onChange,
  placeholder,
  loading,
  defaultValue,
}) {
  const state = useLocalStore(() => {
    return {
      inputRef: React.createRef<any>(),
      value: defaultValue ?? '',
      handleSearchClick(ev: React.FormEvent<any>) {
        ev.preventDefault()
        onSearchClick?.(this.value === '' ? undefined : Buffer.from(this.value).toString('base64'))
      },
      handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.value = e.currentTarget.value
        const encodedString = Buffer.from(this.value).toString('base64')
        onChange?.(encodedString)
      },
    }
  })

  return (
    <form onSubmit={state.handleSearchClick}>
      <Bar>
        <BarItem isFilling>
          <Input
            inputRef={state.inputRef}
            id="search"
            inputSize="small"
            placeholder={placeholder}
            data-testid="search"
            value={state.value}
            leftAddons={
              <Addon type="filled">
                <Icon size="medium" name="search-glass" />
              </Addon>
            }
            onChange={state.handleChange}
            rightAddons={
              <Addon
                data-testid="btn-clear"
                type="filled"
                onClick={_ => {
                  state.value = ''
                  state.inputRef.current.focus()
                  onChange?.('')
                }}>
                <Icon size="medium" name="close" />
              </Addon>
            }
          />
        </BarItem>
        {onSearchClick && (
          <BarItem>
            <ButtonLayout className="mb-none">
              <ButtonPrimary
                data-testid="submit"
                style={{ paddingRight: '2.8rem', paddingLeft: '2.8rem', minWidth: 147 }}
                onClick={state.handleSearchClick}
                isDisabled={loading}
                isLoading={loading}
                size="small">
                <Trans>Hľadať</Trans>
              </ButtonPrimary>
            </ButtonLayout>
          </BarItem>
        )}
      </Bar>
    </form>
  )
})

SearchBar.displayName = 'SearchBar'

/**
 * Returns function used to filter `data` based on input `term` in selected object's properties (`propertiesToSearchIn`)
 * @param term
 * @param propertiesToSearchIn
 */
// export const createSearchFilter = (term: string, propertiesToSearchIn: string[]) => (
//   data: any[]
// ) => {
//   return data.filter(i => {
//     const extractedText = values(pick(i, propertiesToSearchIn)).join('')
//     return extractedText.toLowerCase().includes(term.toLowerCase())
//   })
// }
