import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useAuth } from '../../utils/hooks/useAuth'
import { SCOPES } from '../../../config/constants'
import { Section, Bar, BarItem, Container, Loader } from '../../ui-kit'
import { Auth } from './Auth'

interface AuthorizedProps {
  scope: SCOPES

  children: JSX.Element
}

export const Authorized = (props: AuthorizedProps) => {
  const { isAuthorized, isAuthenticated, isLoading } = useAuth(props.scope)
  const [showLoader, setShowLoader] = useState(false)
  const loaderTimeoutRef = useRef<any>()

  const hasAccess = useMemo(() => isAuthenticated && isAuthorized, [isAuthenticated, isAuthorized])

  useEffect(() => {
    loaderTimeoutRef.current = setTimeout(() => {
      setShowLoader(true)
    }, 50)
    return () => clearTimeout(loaderTimeoutRef.current)
  }, [])

  useEffect(() => {
    const token = Auth.getAccessToken()

    if (token && Auth.forceChangeEmail(token)) {
      location.href = `/auth/change-email`
    }
  }, [])

  if (isLoading) {
    if (showLoader) {
      return (
        <main id="main">
          <Section color="light-grey" size="xxlarge">
            <Container>
              <Bar align="middle">
                <BarItem isFilling style={{ display: 'flex', justifyContent: 'center' }}>
                  <Loader size="large" />
                </BarItem>
              </Bar>
            </Container>
          </Section>
        </main>
      )
    }
    return null
  }

  if (isLoading || !hasAccess) return null
  return props.children
}
