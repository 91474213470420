import React, { useEffect } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'

import { Trans } from '@lingui/macro'
import { pull } from 'lodash-es'
import { useRefHasChanged } from '../../../utils/hooks/useRefHasChanged'
import { Button, ButtonDropdown, Checkbox, DropdownItem, RadioCheckGroup } from '../../../ui-kit'

export type DropdownFilterDataType = { id: string; value: string; valueSelected?: string }[]

type Props = {
  /**
   * !!! pass by reference, otherwise widget will reset after every update
   */
  data: DropdownFilterDataType // TODO: rename as in Options
  labelAllItems: string
  labelSelectedItems: string
  onChange(ids: string[]): void
  id: string
  defaultChecked?: string[]
  ignoreDataRefCheck?: boolean
}

export const CommonDropdownFilter = observer((props: Props) => {
  const { data, onChange, labelAllItems, labelSelectedItems, id, ignoreDataRefCheck } = props
  const dataHasChanged = useRefHasChanged(data)

  if (!ignoreDataRefCheck && dataHasChanged) console.error(`\`data\` reference was mutated!`, data)

  const state = useLocalStore(_props => {
    return {
      allChecked: false,
      currentlyChecked: [] as string[],
    }
  }, props)

  const checkAll = () => {
    state.currentlyChecked.push(...props.data.map(i => i.id))
    state.allChecked = true
  }

  useEffect(() => {
    if (!props.defaultChecked) {
      checkAll()
    } else {
      state.currentlyChecked.push(...props.defaultChecked)
      if (
        getUniqueIds(props.defaultChecked).length ===
        getUniqueIds(props.data?.map(d => d.id)).length
      ) {
        state.allChecked = true
      }
    }
  }, [data])

  useEffect(() => {
    if (!props.defaultChecked) {
      checkAll()
    }
  }, [props.defaultChecked])

  const getUniqueIds = (checkedIds: string[]) => {
    const uniqueIds: string[] = []
    checkedIds.forEach(checkedId => !uniqueIds.includes(checkedId) && uniqueIds.push(checkedId))
    return uniqueIds
  }

  const getLabel = () => {
    if (state.allChecked) {
      return labelAllItems
    }
    const uniqueIds = getUniqueIds(state.currentlyChecked)
    const count = uniqueIds.length
    if (count) {
      let info = `${count}`
      const firstChecked = data.find(d => d.id === state.currentlyChecked[0])?.valueSelected
      if (count === 1 && firstChecked) {
        info = firstChecked
      }
      return `${labelSelectedItems} (${info})`
    }
    return labelAllItems
  }

  return (
    <>
      <ButtonDropdown
        data-testid={`${id}-btn`}
        dropdownInteractive
        type="link"
        space="condensed"
        icon
        iconSize="medium"
        dropdownClassName="dropdownRadioCheckGroup"
        content={getLabel()}
        size="small">
        <RadioCheckGroup style={{ padding: '1rem 1rem 0 1rem' }}>
          {data.map(item => (
            <DropdownItem className="no-pad" key={item.id}>
              <Checkbox
                isChecked={state.currentlyChecked.includes(item.id)}
                size="small"
                id={`${id}-${item.id}`}
                formControlClass="mb-tiny"
                onChange={e => {
                  const { checked } = e.currentTarget

                  if (checked) {
                    state.currentlyChecked.push(item.id)
                    if (state.currentlyChecked.length === data.length) {
                      state.allChecked = true
                    }

                    onChange(getUniqueIds(state.currentlyChecked))
                  } else {
                    state.currentlyChecked = pull(state.currentlyChecked, item.id)
                    if (state.currentlyChecked.length === 0) {
                      onChange(getUniqueIds(data.map(i => i.id)))
                    } else {
                      state.allChecked = false
                      onChange(getUniqueIds(state.currentlyChecked))
                    }
                  }
                }}>
                {item.value}
              </Checkbox>
            </DropdownItem>
          ))}
        </RadioCheckGroup>

        <div style={{ borderTop: '1px solid #e8e8e8' }} />

        <div style={{ backgroundColor: '#fff' }}>
          <div className="dropdownFooter">
            {state.allChecked ? (
              <Button
                size="small"
                type="link"
                space="nospace"
                style={{ marginBottom: 0 }}
                onClick={() => {
                  state.currentlyChecked = []
                  state.allChecked = false
                  onChange(getUniqueIds(state.currentlyChecked))
                }}>
                <Trans>Zrušiť vybrané</Trans>
              </Button>
            ) : (
              <Button
                size="small"
                type="link"
                space="nospace"
                style={{ marginBottom: 0 }}
                onClick={() => {
                  state.currentlyChecked = [...data.map(i => i.id)]
                  state.allChecked = true
                  onChange(getUniqueIds(state.currentlyChecked))
                }}>
                <strong>
                  <Trans>Vybrať všetky</Trans>
                </strong>
              </Button>
            )}
          </div>
        </div>
      </ButtonDropdown>
    </>
  )
})

CommonDropdownFilter.displayName = 'CommonDropdownFilter'
