import { useRef } from 'react'
import { useDidMount } from './useDidMount'

/**
 * check if initial reference to object has changed
 */
export function useRefHasChanged<T>(value: T) {
  const ref = useRef<T>()

  useDidMount(() => {
    ref.current = value
  })

  return ref.current != null && ref.current !== value
}
