import React from 'react'
import { observer } from 'mobx-react-lite'
import { i18n } from '../../utils/intl'
import {
  Button,
  ButtonLayout,
  ButtonPrimary,
  DialogContent,
  DialogFooter,
  DialogHeader,
  ReachDialog,
} from '../../ui-kit'

export const ReachConfirmDialog: React.SFC<{
  id?: string
  title?: string
  message?: React.ReactNode
  buttonConfirmText?: React.ReactNode
  buttonConfirmAction?: () => void
  buttonCancelText?: string
  disabledConfirmButton?: boolean
  hideOnDismiss?: boolean
  hideCloseBtn?: boolean
  hideCancelBtn?: boolean
  close: () => void
  size?: 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge' | undefined
}> = observer(
  ({
    id,
    title = i18n.t`Upozornenie`,
    message = i18n.t`Naozaj chcete vykonať túto operáciu?`,
    buttonConfirmText = i18n.t`Potvrdiť`,
    buttonConfirmAction = () => ({}),
    buttonCancelText = i18n.t`Zrušiť`,
    disabledConfirmButton,
    hideCloseBtn,
    hideOnDismiss,
    hideCancelBtn,
    close,
    size,
  }) => {
    return (
      <ReachDialog size={size} id={id} onDismiss={hideOnDismiss ? undefined : close}>
        <DialogHeader title={title} hideCloseBtn={hideCloseBtn} />
        <DialogContent>{message}</DialogContent>
        <DialogFooter>
          <ButtonLayout direction="horizontal" className="align-items-center">
            {!hideCancelBtn && (
              <Button onClick={close} data-testid="btn-cancel">
                {buttonCancelText}
              </Button>
            )}
            <ButtonPrimary
              onClick={buttonConfirmAction}
              isDisabled={disabledConfirmButton}
              data-testid="btn-confirm">
              {buttonConfirmText}
            </ButtonPrimary>
          </ButtonLayout>
        </DialogFooter>
      </ReachDialog>
    )
  }
)

ReachConfirmDialog.displayName = 'ReachConfirmDialog'
