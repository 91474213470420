/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
import {
  Branch,
  Department,
  DeliveryAddress,
  Employee,
  Pagination,
  ExportsBulk,
} from '@odiupsk/up-api-client'
import { observable, computed } from 'mobx'
import { ApiClientsRepository } from '../../common/api/ApiClientsRepository'
import { maxPerPage } from '../../common/utils/maxPerPage'

export class BranchStore {
  @observable.ref branch?: Branch = undefined

  async fetch() {
    this.branch = await ApiClientsRepository.fetchBranch(this.clientId)
  }

  constructor(public clientId: string) {}
}

export class DepartmentStore {
  constructor(public clientId: string) {}

  @observable.ref department?: Department

  async fetch(id: string) {
    this.department = await ApiClientsRepository.fetchDepartment(this.clientId, id)
  }

  async deleteDepartment(id: string) {
    await ApiClientsRepository.deleteDepartment(this.clientId, id)
  }

  async saveDepartment(id: string, body: Partial<Department>) {
    await ApiClientsRepository.patchDepartment(this.clientId, id, body)
  }

  // departments export here

  @computed get id() {
    return this.department && this.department.id
  }
}

export class DepartmentsStore {
  constructor(public clientId: string) {}

  @observable.ref departments: Department[] = []

  async fetch() {
    this.departments = (await ApiClientsRepository.fetchDepartments(this.clientId)).data!
  }
}

export class DeliveryAddressStore {
  constructor(public clientId: string) {}

  @observable.ref deliveryAddress?: DeliveryAddress

  async fetch(branchId: string, id: string) {
    this.deliveryAddress = await ApiClientsRepository.fetchDeliveryAddress(
      this.clientId,
      branchId,
      id
    )
  }
}

export class DeliveryAddressesStore {
  constructor(public clientId: string, public branchId: string) {}

  @observable.ref deliveryAddresses: DeliveryAddress[] = []

  async fetch() {
    this.deliveryAddresses = (
      await ApiClientsRepository.fetchDeliveryAddresses(this.clientId, this.branchId)
    ).data!
  }

  // export delivery addresses
  async fetchClientBranchesExport(params: any) {
    await ApiClientsRepository.fetchClientBranchesExport(this.clientId, this.branchId, params)
  }

  async fetchClientBranchesExportBulk(ids: ExportsBulk) {
    await ApiClientsRepository.fetchClientBranchesExportBulk(this.clientId, this.branchId, ids)
  }
}
export class EmployeesStore {
  constructor(public clientId: string) {}

  @observable.ref employees: Employee[] = []

  @observable.ref allIds: string[] = []

  @observable.ref pagination: Pagination = { object_count: 0, page: 0 }

  @observable.ref exportBlob: string = ''

  async fetch(params: any) {
    const res = await ApiClientsRepository.fetchEmployees(this.clientId, params)
    this.employees = res.data!
    this.pagination = res.pagination
  }

  async fetchAllEmployeesIds(params?: any) {
    const res = await ApiClientsRepository.fetchAllEmployeesIds(this.clientId, {
      ...params,
      fields: ['id'],
      per_page: maxPerPage,
    })
    this.allIds = res.data!.map(employee => employee.id)
  }

  async fetchClientEmployeesExport(params: any) {
    await ApiClientsRepository.fetchClientEmployeesExport(this.clientId, params)
  }

  async fetchClientEmployeesExportBulk(body: ExportsBulk) {
    await ApiClientsRepository.fetchClientEmployeesExportBulk(this.clientId, body)
  }

  // async fetchEmployeesAndInitTable(params: any, manageTableStore: {  StoreManageEmployees}) {
  //   await this.employees.fetch(params)
  //   await this.employees.fetchAllEmployeesIds()

  //   this.employeesTable.setFromResponse({
  //     data: this.employees.employees,
  //     object_count: this.employees.pagination.object_count || 0,
  //   })

  //   this.employeesTable.setAllIds(this.employees.allIds)
  // }
}
